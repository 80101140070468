<template>
  <div id="app">
    <header class="main-header">
      <div class="top_menu">
        <nav class="navbar navbar-expand-lg navbar-light bg-transparent">
          <div class="container">
            <router-link class="navbar-brand" to="/">
              <a>
                <img
                  class="logo-sticky"
                  src="assets/images/bio.png"
                  alt="logo"
                />
              </a>
            </router-link>
            <button
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarNav"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ml-auto" id="nav">
                <li class="nav-item">
                  <router-link class="nav-link js-scroll-trigger" to="/"
                    >Home</router-link
                  >
                </li>
                <!-- <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Products
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <router-link class="dropdown-item" to=""
                      >Mobile App</router-link
                    >
                    <router-link class="dropdown-item" to="/WebApp"
                      >Web App</router-link
                    >
                    <router-link class="dropdown-item" to=""
                      >Win App</router-link
                    >
                  </div>
                </li> -->
                <li class="nav-item">
                  <router-link
                    class="nav-link js-scroll-trigger"
                    to="/Download"
                    >Downloads</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link js-scroll-trigger" to="/Contacts"
                    >Contact</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link js-scroll-trigger" to="/About"
                    >About Us</router-link
                  >
                </li>
                <!-- <li class="nav-item">
                  <a
                    href="https://storage.nextbalance.net/s/6PbdnK26H9esGXS"
                    class="nav-link js-scroll-trigger"
                    target="_blank"
                  >
                    Video Training
                  </a>
                </li> -->
                <!-- <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <router-link class="dropdown-item" to=""
                      >Terms of Service</router-link
                    >
                    <router-link class="dropdown-item" to=""
                      >System Requirement</router-link
                    >
                    <router-link class="dropdown-item" to=""
                      >Download Software</router-link
                    >
                  </div>
                </li> -->
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style scoped>
.dropdown:hover > .dropdown-menu {
  display: block;
}
.contact-support {
  float: right;
  /* font-family: cs_chatthaiuiregular,sans-serif; */
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  /* color: var(--color_gray55); */
  padding: 11.5px 20px;
  text-align: center;
  background: var(--system_color_white);
  box-shadow: 2px 2px 10px 0 rgb(6 6 6 / 14%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: white;
}
.lang-icon {
  float: right;
  padding-left: 15px;
  padding-top: 10px;
}
</style>
